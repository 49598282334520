@import "modules/variables.scss";
@import "modules/mixins.scss";

.container {
    .run-as-user {
        background-color: $white;
        display: inline-block !important;
        margin: 5px;
        height: 42px;
        padding: 0 20px;
        color: $blueSynchrone;
        display: block;
        line-height: 42px;

        &:hover {
            color: $white;
            background-color: $menuColor;
        }
    }
}
