@import "modules/variables.scss";
@import "modules/mixins.scss";

#union-container {
    width: 1200px;
    margin: 90px auto 0 auto;
    padding-bottom: 90px;

    &.backend {
        margin-top: 115px;
    }

    .unions-headline {
        font-weight: 700;
        font-size: 18px;
    }

    .unions-infos {
        font-size: 14px;
        margin-bottom: 40px;
    }

    .union-links-container {
        display: flex;
        justify-content: space-between;

        .union-link {
            border: 2px solid black;
            background-repeat: no-repeat;
            border-radius: 10px;
            width: 350px;
            height: 240px;
            background-color: white;
            cursor: pointer;
            background-position: center;
        }
    }
}
