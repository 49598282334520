@import "modules/variables.scss";
@import "modules/mixins.scss";

.container-instagram {
    @include pie-clearfix();
    position: relative;
    border-radius: 4px;
    background-color: #ffffff;
    margin: 10px;
    border: 1px solid #a6b9c5;
    height: 100%;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans",
        Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial,
        sans-serif;
    line-height: 18px;
    text-align: left;
    font-weight: normal;

    .top-content {
        @include pie-clearfix();
        padding: 10px;
        height: 54px;
        position: relative;

        .fa-instagram {
            font-size: 28px;
            position: absolute;
            right: 10px;
            top: -36px;
        }

        .link-instagram {
            width: 150px;

            .synchrone-img {
                float: left;
                height: 30px;
                width: 30px;
                margin: 2px 0;
                border-radius: 50%;
                //border: 1px solid transparent;
            }

            .span-synchrone {
                padding-left: 10px;
                font-size: 14px;
                float: left;

                a {
                    color: #262626;
                    font-weight: 600;
                    font-size: 14px;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .place-synchrone {
                padding-left: 10px;
                color: #262626;
                font-size: 12px;
                float: left;
            }
        }
        .view-profil {
            position: absolute;
            top: 15px;
            right: 15px;
            font-weight: 600;
            padding: 5px 12px;
            text-decoration: none;
            border-radius: 3px;
            background-color: #3897f0;
            color: white;

            &:hover {
                background-color: #003569;
            }
        }
    }

    .img-instagram {
        width: 100%;

        a {
            img {
                width: 100%;
            }
        }
    }

    .content-instagram {
        padding: 10px;
        font-size: 14px;
        line-height: 18px;

        @include onSmallMobile {
            padding: 5px 10px 5px 10px;
        }

        p {
            margin: unset;
        }

        a {
            text-decoration: none;
            color: $bluetwitter;
        }
    }
}
