@import "modules/variables.scss";
@import "modules/mixins.scss";

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $duckColor;
    height: 5px;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $duckColor;
}

.remote-working-container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: normal;
    text-align: left;
    padding-left: 40px;
    margin-bottom: 25px;

    .remote-working-icon {
        margin-right: 5px;
    }

    > .checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked + .checkmark:after {
            display: block;
        }
    }

    > .checkmark {
        height: 20px;
        width: 20px;
        display: inline-block;
        position: relative;
        left: 10px;
        top: 5px;
        background-color: #eee;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 5px;
            width: 6px;
            height: 12px;
            border: solid $menuColor;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
