@import "modules/variables.scss";
@import "modules/mixins.scss";

.period-container {
    margin: 90px auto 0 auto;
    width: 1200px;

    > .year {
        font-weight: 700;
        padding: 20px 0;
        text-align: center;

        > .current-year {
            position: relative;
            top: -8px;
        }

        > .next-year-btn,
        > .previous-year-btn {
            font-size: 40px;
            padding: 0 50px;

            i {
                cursor: pointer;
            }
        }

        .unavailable {
            color: $inputBorderColor;
            cursor: initial !important;
        }
    }

    > .period-container-table {
        > .period-container-table-header {
            color: #9b9b9b;
            font-weight: 600;
            @include pie-clearfix();
            height: 60px;

            > .period-header-month {
                text-align: center;
                float: left;
                width: 17%;
                border-right: 1px dashed #9b9b9b;
                height: 60px;
                padding: 5px;
            }

            > .period-header-validated2 {
                text-align: center;
                float: left;
                width: 10%;
                border-right: 1px dashed #9b9b9b;
                height: 60px;
                padding: 5px;
            }

            > .period-header-validated1 {
                text-align: center;
                float: left;
                width: 15%;
                border-right: 1px dashed #9b9b9b;
                height: 60px;
                padding: 5px;
            }

            > .period-header-submitted {
                text-align: center;
                float: left;
                width: 15%;
                border-right: 1px dashed #9b9b9b;
                height: 60px;
                padding: 5px;
            }

            > .period-header-non-submitted {
                text-align: center;
                float: left;
                width: 15%;
                border-right: 1px dashed #9b9b9b;
                height: 60px;
                padding: 5px;
            }

            > .period-header-total {
                text-align: center;
                float: left;
                width: 10%;
                border-right: 1px dashed #9b9b9b;
                height: 60px;
                padding: 5px;
            }

            > .period-header-actions {
                text-align: center;
                float: left;
                height: 60px;
                width: 15%;
                padding: 5px;
            }
        }

        > .period-row {
            @include pie-clearfix();
            border-top: 1px dashed #9b9b9b;
            height: 60px;

            > .period-row-month {
                float: left;
                width: 17%;
                font-weight: 700;
                border-right: 1px dashed #9b9b9b;
                height: 60px;
                padding-top: 20px;

                > .lock-icon {
                    float: right;
                    width: 85px;
                    text-align: center;
                    margin-right: 5px;

                    > .lock-date {
                        font-weight: normal;
                    }

                    > .fa-unlock {
                        color: $menuColor;
                    }
                }

                > .locked-icon-container {
                    margin-top: -5px;
                }
            }

            > .period-row-validated2 {
                text-align: center;
                float: left;
                width: 10%;
                height: 60px;
                padding-top: 20px;
                border-right: 1px dashed #9b9b9b;
            }

            > .period-row-validated1 {
                text-align: center;
                float: left;
                height: 60px;
                width: 15%;
                padding-top: 20px;
                border-right: 1px dashed #9b9b9b;
            }

            > .period-row-submitted {
                text-align: center;
                float: left;
                height: 60px;
                width: 15%;
                padding-top: 20px;
                border-right: 1px dashed #9b9b9b;
            }

            > .period-row-non-submitted {
                text-align: center;
                float: left;
                width: 15%;
                height: 60px;
                padding-top: 20px;
                border-right: 1px dashed #9b9b9b;
            }

            > .period-row-total {
                text-align: center;
                float: left;
                height: 60px;
                padding-top: 20px;
                width: 10%;
                border-right: 1px dashed #9b9b9b;
            }

            > .period-row-actions {
                text-align: center;
                float: left;
                width: 15%;
                padding-top: 20px;
                height: 60px;

                > .lock-action, .force-submit-action {
                    display: inline-block;
                    cursor: pointer;
                    font-size: 20px;
                    margin: 0 5px;
                }

                > .open-comment {
                    display: inline-block;
                    cursor: pointer;
                    font-size: 20px;
                }

                .unavailable {
                    color: $inputBorderColor;
                    cursor: initial;
                }
            }
        }
    }
}
