@import "modules/variables.scss";
@import "modules/mixins.scss";

.rex {
    border-radius: 4px;
    background-color: $white;
    border: 1px solid #a6b9c5;
    padding: 15px;
    display: block;

    > img {
        width: 100%;
    }

    > .rex-title {
        color: $blueSynchrone;
        font-size: 20px;
        font-weight: $semiBold;
        margin-bottom: 15px;
        line-height: 1.3;
    }
}
