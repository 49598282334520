@import "modules/variables.scss";
@import "modules/mixins.scss";

#union-container {
    .sections-container {
        column-count: 2;
        break-inside: avoid;
        page-break-inside: avoid;
        -webkit-column-break-inside: avoid;
    }

    .unions-headline {
        font-weight: 700;
        margin: 20px 0;

        .unions-headline-grey {
            color: $blueSynchrone;
        }
    }

    .union-infos {
        margin-top: 30px;

        .union-logo {
            display: inline-block;
            width: 100px;
            height: 100px;
            background-size: cover;
            margin-right: 20px;
            vertical-align: top;
        }

        .union-speech {
            display: inline-block;
            width: calc(100% - 150px);

            .union-description {
                margin: 15px 0;
                display: flex;
                align-items: center;

                .update-union-description-form {
                    width: 100%;
                    display: flex;
                    align-items: center;
                }

                .shorter-description {
                    width: 96%;
                }

                .union-description-textarea {
                    height: 88px;
                    width: 94%;
                    resize: none;
                    margin-right: 15px;
                    font-family: "Open Sans", sans serif;
                }

                .union-description-actions {
                    float: right;

                    > .union-description-actions-item {
                        font-size: 24px;
                        -webkit-animation: 0.2s pop-in ease-in;
                        -moz-animation: 0.2s pop-in ease-in;
                        -ms-animation: 0.2s pop-in ease-in;
                        cursor: pointer;

                        &.confirm-action {
                            color: $green;
                        }

                        &.cancel-action {
                            color: $corail;
                        }
                    }
                }
            }

            .union-word {
                font-size: 18px;
                font-weight: 700;
            }
        }
    }
}

@keyframes pop-in {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
