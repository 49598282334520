@import "modules/variables.scss";
@import "modules/mixins.scss";

.identity-block {
    // display: inline-block;
    border-right: 2px solid #f2f2f2;
    padding: 12px;
    width: 23%;

    .identity-block-identity {
        font-size: 15px;
        font-weight: 700;
    }

    .identity-block-person-type {
        display: inline-block;
        width: 60px;
        background-color: #e9e8e9;
        border-radius: 9px;
        font-size: 9px;
        text-align: center;
        font-weight: 600;
        letter-spacing: 0.08px;
        line-height: 7.4px;
        margin-right: 5px;
    }

    .particularities-block {
        float: right;
        margin-top: 15px;

        .particularity-icon {
            font-size: 24px;
            margin-right: 5px;
        }

        &.backwarded {
            color: $corail;
        }
    }

    .identity-block-matricule {
        display: inline-block;
    }

    .identity-block-matricule,
    .identity-block-manager {
        font-size: 13px;
    }

    .fa-stack {
        font-size: 12px;

        .inside-icon {
            font-size: 14px;
        }
    }
}
