@import "modules/variables.scss";
@import "modules/mixins.scss";

.cra-container {
    margin-top: 90px;

    .cra-list {
        margin-left: 80px;
        padding-left: 40px;

        .no-activity {
            text-align: center;
            margin-top: 25px;
            width: 1200px;
        }

        .cra-list-title-container {
            padding-bottom: 20px;
            display: flex;

            .cra-list-title {
                font-size: 32px;
                height: 70px;
                align-items: flex-end;
                display: flex;
            }

            .caption-container {
                margin-left: 60px;
                display: flex;
                align-items: flex-end;

                .caption {
                    margin-right: 20px;
                    display: inline-block;

                    .caption-color {
                        vertical-align: top;
                        display: inline-block;
                        height: 20px;
                        width: 8px;
                        margin-right: 5px;
                    }

                    .caption-title {
                        display: inline-block;
                    }
                }
            }
        }

        > .current-period-bar {
            background-color: $menuColor;
            color: $white;
            text-align: center;
            width: 1200px;
            height: 40px;
            line-height: 40px;
            padding: 0 10px;

            .previous-month,
            .next-month {
                margin-top: 4px;
                cursor: pointer;
            }

            .previous-month {
                float: left;
                font-size: 34px;
            }

            .next-month {
                float: right;
                font-size: 34px;
            }

            .current-period-label {
                display: inline-block;
                font-size: 18px;
                text-transform: uppercase;

                > .month-label {
                    display: inline-block;
                    font-weight: 700;
                    margin-right: 5px;
                }

                > .year-label {
                    display: inline-block;
                    font-weight: 100;
                }
            }
        }

        .cra-list-header {
            width: 1200px;
            color: #9b9b9b;

            .header-identity {
                display: inline-block;
                width: 23%;
                padding: 12px;
            }

            .header-activity-type {
                display: inline-block;
                width: 30%;
                padding: 12px;
            }

            .header-days-amount {
                display: inline-block;
                width: 5%;
                padding: 12px;
            }

            .header-actions {
                display: inline-block;
                width: 22%;
                padding: 12px;
            }

            .header-overtime {
                display: inline-block;
                width: 18%;
                padding: 12px;
            }
        }
    }

    > .page-controls {
        text-align: center;
        width: 1200px;
        margin-left: 120px;

        > .page-number {
            display: inline-block;
            margin: 0 15px;
        }

        > .next-page,
        > .previous-page {
            cursor: pointer;
            display: inline-block;
        }
    }
}
