@import "modules/variables.scss";
@import "modules/mixins.scss";

a {
    text-decoration: none;
}

.news-article {
    width: 350px;
    //min-height: 250px;
    margin: 6px 5px;
    // opacity: 0.01;
    transition: opacity 350ms;
    background-color: $white;
    border-radius: 4px;

    @include onMobile {
        position: static !important;
        margin: 10px auto;
    }

    @include onSmallMobile {
        width: 100%;

        .fb_iframe_widget > span {
            width: 100% !important;
        }
    }

    twitterwidget {
        display: inline !important;
    }

    iframe {
        margin: 0 !important;
        width: 350px !important;

        @include onSmallMobile {
            width: 100% !important;
        }
    }

    .twitter-tweet {
        margin: 0 !important;
    }

    &.news-subscribe {
        background-color: $blueSynchrone;
        color: $white;
        padding: 15px;
        border: 1px solid #a6b9c5;
        line-height: 1.4;
        border-radius: 4px;

        span {
            display: block;
        }

        #subscribe-form {
            .field-widget {
                margin-left: 0;
                width: 100%;
            }

            .field {
                width: calc(100% - 40px);
                input {
                    background-color: transparent;
                    width: 100%;
                }
            }

            button {
                color: $white;
                width: 32px;
            }
        }
    }
}
