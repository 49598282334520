@import "modules/variables.scss";
@import "modules/mixins.scss";

.interview {
    border-radius: 4px;
    background-color: $menuColor;
    padding: 15px 25px 25px 25px;
    color: $white;
    line-height: 1.4;
    display: block;

    @include onSmallMobile {
        padding: 15px;
    }

    > img {
        width: 100%;
    }

    > .interview-title {
        font-size: 24px;
        font-weight: $semiBold;
        line-height: 1.3;
        padding-left: 50px;
        position: relative;
        margin-bottom: 15px;

        &:before {
            position: absolute;
            @include font-awesome("\f130");
            font-weight: 600;
            left: 0;
            font-size: 30px;
        }
    }

    > .interview-content {
        font-size: 18px;
        margin: 15px 0 5px 0;
    }

    > .interview-summary {
        font-size: 14px;
        font-style: italic;
    }
}
