@import "modules/variables.scss";

.lb-absence-history {
    position: relative;
    font-family: $mainFont;
    text-align: initial;
    height: 75vh;
    color: $blueSynchrone;
    line-height: initial;

    .no-absence {
        color: #979797;
        text-align: center;
        margin-top: 325px;
    }

    .lb-absence-history-timeline {
        width: calc(100% - 52px);
        margin-left: 26px;
        margin-top: 25px;
        margin-bottom: 30px;
        line-height: 34px;

        .timeline-year {
            display: inline-block;
            color: $blueSynchrone;
            height: 34px;
            width: 64px;
            font-size: 16px;
            font-weight: 900;
            margin-right: 14px;
            text-decoration: none;
            border: 1px solid $blueSynchrone;
            text-align: center;

            &.active {
                color: white;
                background-color: $vacationColor;
                border: none;
            }
        }

        .switch-year {
            color: $blueSynchrone;
            cursor: pointer;
            font-size: 29px;
            margin-top: 4px;

            &.disabled {
                color: #d8d8d8;
                cursor: initial;
            }

            &.previous-year {
                float: right;
            }

            &.next-year {
                margin-right: 20px;
                float: left;
            }
        }
    }

    .lb-absence-history-header {
        height: 70px;
        line-height: 70px;
        color: $white;
        background-color: $vacationColor;
        font-size: 16px;
        font-weight: 700;
        padding: 0 29px;

        .close-lb {
            cursor: pointer;
            position: absolute;
            right: 33px;
            font-size: 42px;
            top: 15px;
            color: $white;
        }
    }

    .lb-absence-history-table-header {
        display: inline-block;
        width: calc(100% - 52px);
        margin-left: 26px;
        height: 30px;
        line-height: 30px;
        background-color: #f4f4f4;
        color: #9b9b9b;
        font-size: 15px;
        font-weight: 600;

        .table-header-year {
            display: inline-block;
            width: 5%;
        }

        .table-header-status {
            text-align: center;
            display: inline-block;
            width: 18%;
        }

        .table-header-dates {
            display: inline-block;
            width: 40%;
        }

        .table-header-total {
            display: inline-block;
            width: 11%;
        }

        .table-header-type {
            display: inline-block;
            width: 25%;
        }
    }

    .lb-absence-history-table {
        height: calc(75vh - 200px);
        overflow-y: auto;
        line-height: 25px;
        width: calc(100% - 52px);
        margin-left: 26px;

        > .absence-by-year-container {
            border-bottom: 1px solid #979797;
            padding-bottom: 45px;
            margin-bottom: 45px;

            > .year {
                font-size: 20px;
                font-weight: 900;
            }
        }

        > .absence-by-year-container:last-of-type {
            border: none;
        }
    }
}
