@import "modules/variables.scss";
@import "modules/mixins.scss";

.filter-container {
    background-color: $white;
    height: calc(100vh - 90px);
    transition: transform 400ms;
    transform: translateX(-420px);
    width: 500px;
    padding-left: 105px;
    padding-top: 28px;
    position: fixed;
    z-index: 500;

    .filter-container-icons {
        .filter-close {
            font-size: 0px;
            transition: font 300ms;
        }

        .filter-open {
            font-size: 48px;
            transition: font 300ms;
        }

        .filter-open,
        .filter-close {
            position: absolute;
            right: 16px;
            top: 16px;
            cursor: pointer;
        }
    }

    &.filter-container-open {
        overflow: auto;
        transform: translateX(0);

        .filter-container-icons {
            .filter-close {
                font-size: 48px;
            }

            .filter-open {
                font-size: 0px;
            }
        }
    }

    .filter-container-header {
        .filter-container-title {
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    .select-container {
        margin-top: 25px;
        width: 300px;

        .select-container__control {
            border: none;
            border-bottom: 1px solid black;
            border-radius: initial;
        }

        .select-container__control--is-focused {
            box-shadow: initial;
        }

        .select-container__indicator-separator {
            background-color: $white;
        }

        .select-container__value-container {
            padding: 0;
        }

        .select-container__placeholder {
            color: $blueSynchrone !important;
        }

        .select-container__indicator {
            color: $blueSynchrone !important;
        }

        .select-container__menu {
            margin-top: 0;
        }
    }

    .states-container {
        margin-top: 20px;

        .states-container-header {
            font-weight: 600;
            margin-bottom: 10px;
        }

        .states-container-checkboxes {
            .states-row {
                margin-bottom: 5px;

                input[type="checkbox"] {
                    display: inline;
                }

                label {
                    display: inline;
                }
            }
        }
    }

    .person-type-container {
        margin-top: 20px;

        .person-type-container-header {
            font-weight: 600;
            margin-bottom: 10px;
        }

        .person-type-container-checkboxes {
            .person-type-row {
                margin-bottom: 5px;

                input[type="checkbox"] {
                    display: inline;
                }

                label {
                    display: inline;
                }
            }
        }
    }

    .particularities-container {
        margin-top: 20px;

        .particularities-container-header {
            font-weight: 600;
            margin-bottom: 10px;
        }

        .particularities-container-checkboxes {
            .particularities-row {
                margin-bottom: 5px;

                input[type="checkbox"] {
                    display: inline;
                }

                label {
                    display: inline;
                }
            }
        }
    }

    form {
        > .next-btn {
            margin-top: 20px;
            border: none;
        }
    }
}
