@import "modules/variables.scss";
@import "modules/mixins.scss";

$columnCounter: 3;
$gutter: 45px;

.home-page {
    position: relative;
    background-image: url("/img/hp-bg.png");
    background-size: cover;
    background-position: center center;
    min-width: 1200px;
    padding-top: 0 !important;

    &:before {
        background-color: rgba(#17313a, 0.9);
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
    }

    .homepage-container {
        width: 100%;
        position: relative;

        #euro-2020-link {
            background-image: url("/img/euro2020/link.png");
            width: 267px;
            height: 116px;
            position: absolute;
            left: 65%;
            cursor: pointer;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }

        #open-cyber-popup-link {
            background-image: url("/img/cyber/link.png");
            background-repeat: no-repeat;
            background-size: contain;
            width: 268px;
            height: 118px;
            position: absolute;
            left: 65%;
            cursor: pointer;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }

            @include onDesktopLittle() {
                width: 201px;
                height: 88.5px;
                left: 72%;
            }
        }

        #united-heroes-popup-link {
            background-image: url("/img/united-heroes/inter-all.png");
            width: 268px;
            height: 118px;
            position: absolute;
            left: 65%;
            cursor: pointer;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }
    }

    .header-container {
        min-height: 90px;
        padding: 3em 0;

        h1 {
            // line-height: 65px;
            color: #ffffff;
            font-family: $mainFont;
            font-size: 48px;
            font-weight: normal;
            padding: 0;
            margin: 0;

            @include onDesktopLittle() {
                font-size: 30px;
            }
        }
    }
}

.home-app-container {
    width: calc(
        (100% - (#{$columnCounter} - 1) * #{$gutter}) / #{$columnCounter}
    );
    float: left;
    height: 475px;

    // @include onDesktopLittle() {
    //     height: 370px;
    // }

    + .home-app-container {
        margin-left: $gutter;
    }
}

.home-apps {
    @include pie-clearfix();
}

//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     width: 1200px;

.home-app {
    padding-top: 1px;
    position: relative;
    height: 100%;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    font-family: $mainFont;

    // @include onDesktopLittle() {
    //     font-size: 15px;
    // }

    .home-app-content {
        font-size: 20px !important;
    }

    i {
        display: block;
        margin: 50px 0 60px 0;
        font-size: 84px;
    }

    .home-news-container {
        a {
            color: $blueSynchrone;
        }

        .home-news-title {
            padding: 10px 26px;
        }

        > .home-news-image-container {
            max-height: 200px;
            overflow: hidden;

            > img {
                position: relative;
                height: 318px;
                transform: translateY(-59px);
            }
        }
    }
}

/* two items */
.home-app:first-child:nth-last-child(2),
.home-app:first-child:nth-last-child(2) ~ .home-app {
    height: calc(50% - 26px / 2);
    font-size: 22px;

    i {
        display: block;
        margin: 18px 12px;
        font-size: 42px;
    }

    + .home-app {
        margin-top: 26px;
    }
}

/* three items */
.home-app:first-child:nth-last-child(3),
.home-app:first-child:nth-last-child(3) ~ .home-app {
    height: calc(33.3333% - 52px / 3);
    font-size: 18px;
    padding-top: 25px;

    i {
        float: left;
        display: block;
        margin: -8px 12px;
        font-size: 42px;
    }

    + .home-app {
        margin-top: 26px;
    }
}

.home-app-btn {
    color: currentColor;
    position: absolute;
    display: block;
    width: 68%;
    border: 1px solid currentColor;
    margin: 0 16%;
    font-size: 15px;
    font-weight: 600;
    bottom: 15px;
    height: 45px;
    line-height: 43px;
    text-align: center;
    border-radius: 30px;
    text-decoration: none;
}

.documents-app {
    font-size: 18px !important;
    padding-top: 25px !important;

    i {
        float: left;
        display: block !important;
        margin: -8px 12px !important;
        font-size: 42px !important;
    }

    .home-app-btn {
        position: relative;
        top: 20%;
        margin-bottom: 30px;
    }
}

.my-activity-app {
    overflow: hidden;
    height: 65% !important;
    position: relative;

    .home-app-content {
        position: relative !important;
        top: 20% !important;
    }

    &:before {
        content: "C.R.A. :";
        text-align: left;
        position: absolute;
        font-style: italic;
        top: -40px;
        left: -25px;
        display: block;
        width: 150px;
        height: 150px;
        font-size: 15px;
        padding-top: 55px;
        padding-left: 50px;
        z-index: 10;
    }

    &:after {
        content: "avant le\a 25 du mois";
        text-align: left;
        position: absolute;
        top: -40px;
        left: -25px;
        display: block;
        width: 100px;
        height: 150px;
        font-size: 12px;
        padding-top: 75px;
        padding-left: 50px;
        // background-image: url("/img/pastille.svg");
        // background-position: top center;
        // background-repeat: no-repeat;
        z-index: 1;
        white-space: pre;
    }

    .pastille {
        position: absolute;
        top: -40px;
        left: -25px;
        width: 150px;
    }

    &.full-activity {
        height: 100% !important;
    }
}

.my-activity-app ~ .home-app {
    height: calc(33.3333% - 52px / 3) !important;
    font-size: 18px !important;
    padding-top: 25px !important;

    i {
        float: left;
        display: block !important;
        margin: -8px 12px !important;
        font-size: 42px !important;
    }

    .home-app-btn {
        position: relative;
        top: 25%;
    }
}

#documents-app-content {
    margin: 0 auto;
    width: 135px;
    display: block;
}
