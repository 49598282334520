@import "modules/variables.scss";
@import "modules/mixins.scss";

.overtime-block {
    border-right: 2px solid #f2f2f2;
    width: 18%;
    display: flex;
    padding: 11px;
    justify-content: space-evenly;

    > .availability-icon {
        display: inline-block;
        height: 19px;
    }

    > .intervention-icon {
        display: inline-block;
        height: 19px;
    }

    > .overtime-details {
        display: inline-block;
        border: 1px solid $blueSynchrone;
        border-radius: 15px;
        font-weight: 700;
        padding: 0 5px;
        font-size: 12px;
        cursor: pointer;
        height: 19px;
    }
}
