@import "modules/variables.scss";
@import "modules/mixins.scss";

.see-more-overtime {
    position: relative;
    left: 130px;
    font-size: 12px;
    top: -21px;
    width: 20px;
    text-align: center;
    border-radius: 50%;
    background-color: $blueSynchrone;
    cursor: pointer !important;
    color: white;
    height: 19px;
    padding-top: 3px;

    i {
        cursor: pointer !important;
    }

    .more-overtime-icon {
        font-weight: 400;
    }

    &:hover {
        transform: scale(1.1);
    }
}
