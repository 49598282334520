@import "modules/variables.scss";
@import "modules/mixins.scss";

.timesheet-notifier {
    z-index: 12000;
    position: fixed;
    top: 102px;
    left: calc((100vw - 1200px) / 2);
    width: 1200px;
    height: 70px;
    line-height: 70px;
    color: $white;
    font-size: 44px;
    transform: translateY(-300%);
    transition: all 1s;

    &.error-notifier {
        background-color: $notifierErrorColor;
    }

    &.success-notifier {
        background-color: $notifierSuccessColor;
    }

    .pre-icon,
    .post-icon {
        display: inline-block;
        width: 100px;
        text-align: center;
    }

    .notifier-text {
        font-weight: 700;
        font-family: "Open Sans";
        font-size: 15px;
        letter-spacing: 0.13px;
        width: 77%;
        vertical-align: bottom;
        display: inline-block;
        margin-left: 75px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.open {
        display: block;
        transform: translateY(0);
        transition: all 1s;
    }
}
