@import "modules/variables.scss";
@import "modules/mixins.scss";

body {
    font-family: $mainFont;
}

#app {
    // padding-top: $headerHeight;
    min-width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

#tutorial-link {
    position: fixed;
    right: 0;
    top: 45%;
    background-color: $menuColor;
    height: 56px;
    width: 56px;
    border-radius: 50px 0 0 50px;
    padding-left: 12px;
    color: white;
    transition-duration: 250ms;
    line-height: 56px;
    overflow: hidden;
    white-space: nowrap;
    z-index: 10;
    font-size: 14px;
    font-weight: 600;

    &:hover {
        width: 200px;
    }

    i {
        font-size: 37px;
        position: relative;
        left: 0;
        top: 8px;
        margin-right: 15px;
    }
}

#dev-indicator {
    position: fixed;
    color: #fff;
    background-color: #eb5961;
    transform: rotate(-45deg);
    bottom: 25px;
    right: -75px;
    width: 250px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 28px;
    font-family: "Montserrat";
    z-index: 9999;
}

#preprod-indicator {
    position: fixed;
    color: $blueSynchrone;
    background-color: $adminHeaderColor;
    transform: rotate(-45deg);
    bottom: 35px;
    right: -60px;
    width: 250px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 28px;
    font-family: "Montserrat";
}

.container {
    width: 1200px;
    margin: 0 auto;
}

#app-container {
    width: 100%;
    height: calc(100% - #{$headerHeight});

    &.loging-page {
        height: 100%;
    }
}

#page-container {
    width: 100%;
    min-height: 100%;
    margin-top: $headerHeight;
    padding-top: 12px;
    // width: 1199px;
    // margin: 12px auto 0 auto;

    .view-only {
        * {
            cursor: initial !important;
        }

        .see-more-overtime {
            cursor: pointer !important;

            i {
                cursor: pointer !important;
            }
        }
    }

    .calendar-container {
        padding-bottom: 90px;

        #error-header {
            background-color: #f05a5a;
            color: #fff;
            height: 70px;
            line-height: 70px;
            width: 1200px;
            padding-left: 23px;
            font-weight: 600;

            i {
                float: left;
                margin-top: 13px;
                margin-right: 105px;
                font-size: 44px;
            }
        }

        .calendar {
            padding: 0 1px;
            display: flex;
            flex-wrap: wrap;

            .day {
                width: 169px;
                height: 120px;
                background-color: #fff;
                margin: 1px;
                cursor: pointer;
                font-family: "Montserrat";
                font-size: 18px;
                font-weight: 300;
                padding: 8px 10px;

                .hovered {
                    color: $white;
                }
            }

            .unavailable {
                background-color: #ebebeb;
                cursor: initial;
                color: #d0d0d0;
            }

            .not-current-month {
                background-color: #f2f2f2 !important;
            }

            .selected {
                background-color: rgba(0, 114, 136, 0.4);
            }

            .not-selectable {
                cursor: default;
            }
        }
    }
}
