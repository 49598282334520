@import "modules/variables.scss";
@import "modules/mixins.scss";

.event-post {
    border-radius: 4px;
    position: relative;
    background-color: $red;
    color: $white;
    padding: 35px;
    line-height: 1.4;
    display: block;
    overflow: hidden;
    height: 270px;

    &:hover {
        .event-post-front {
            transform: translateX(-100%);
        }
        .event-post-back {
            transform: translateX(0);
        }
    }

    > .event-post-front {
        padding: 35px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        background-color: $red;
        z-index: 1;
        transform: translateX(0);
        transition-duration: 250ms;

        > .event-post-header {
            font-size: 24px;
            font-weight: $semiBold;
        }

        > .event-post-title {
            font-size: 18px;
            margin: 15px 0;
            line-height: 1.2;
        }

        > .event-post-date {
            font-size: 20px;
            font-weight: $semiBold;
        }
    }

    > .event-post-back {
        padding: 35px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        background-color: $red;
        z-index: 2;
        transform: translateX(100%);
        transition-duration: 250ms;

        > img {
            max-width: 100%;
        }

        > .event-post-content {
            font-size: 14px;
            margin: 20px 0;
        }
    }
}
