@import "modules/variables.scss";
@import "modules/mixins.scss";

.marketing-post {
    border-radius: 4px;
    border: 1px solid #a6b9c5;
    display: block;
    overflow: hidden;

    > img {
        width: 100%;
        vertical-align: middle;
    }
}
