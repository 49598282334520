@import "modules/variables.scss";
@import "modules/mixins.scss";

.press-release {
    border-radius: 4px;
    background-color: $green;
    padding: 25px 30px 40px 30px;
    color: $white;
    line-height: 1.4;
    display: block;

    > .press-release-title {
        font-size: 24px;
        font-weight: $semiBold;
        margin-bottom: 25px;
        line-height: 1.3;
        padding-left: 50px;
        position: relative;

        &:before {
            position: absolute;
            @include font-awesome("\f0a1");
            font-weight: 600;
            left: 0;
            font-size: 30px;
        }
    }

    > .press-release-content {
        font-size: 18px;
        font-weight: $semiBold;
    }
}
