@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.harassment-item {
    background-color: #f0f9f8;
    padding: 0 12px;
    width: 98%;
    margin: 0 auto;

    .contact-content {
        height: 24px !important;
        line-height: 24px;

        .contact-name,
        .contact-email {
            display: inline-block;
            font-size: 12px;
        }

        .contact-name {
            width: 65%;
            font-weight: 600;
        }

        .contact-email {
            color: $blueSynchrone;
            font-weight: 400;

            i {
                width: 20px;
            }
        }
    }
}

.bold-title {
    font-size: 15px;
    font-weight: 600;
}
